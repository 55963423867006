/* src/components/TomatoPayPayment.css */
.tomato-pay-form {
    max-width: 100%;
    padding: 16px;
    margin: 0 auto;
}

.tomato-pay-form h1 {
    font-size: 1.5em;
    text-align: center;
}

.tomato-pay-form p {
    font-size: 1em;
    word-wrap: break-word;
}

.tomato-pay-form .ant-form-item {
    margin-bottom: 16px;
}

.tomato-pay-form .ant-radio-group {
    display: flex;
    justify-content: space-around;
}

.tomato-pay-form .ant-input {
    width: 100%;
}

.tomato-pay-form .ant-btn {
    width: 100%;
    font-size: 1.2em;
    padding: 10px 0;
}
